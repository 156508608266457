/*
__Seed builder__
  (Read_only) Builder helper
*/
@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Raleway"), local("Raleway-Regular"), url(https://fonts.gstatic.com/s/raleway/v14/1Ptug8zYS_SKggPNyC0IT4ttDfA.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
.module {
  position: relative;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.module select {
  background: white;
  border: 1px solid #d0d0d0;
  width: 100%;
  height: 35px;
}

.multiSelect [class$=-menu] {
  z-index: 1005;
}

.jumbotron {
  background-color: #007473;
  color: white;
  margin: 0px;
  padding: 15px 20px;
}
.jumbotron h4 {
  font-size: 21px;
  margin-bottom: 0px;
}

.form {
  background: #fff;
  max-height: 450px;
  overflow: auto;
  padding: 24px 40px 30px 40px;
}

.module table {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin-top: 12px;
}

.module td, .module th {
  border: 1px solid #ddd;
  padding: 8px;
}
.module td i, .module th i {
  color: #aaa;
  cursor: pointer;
}

.module tr:nth-child(even) {
  background-color: #f2f2f2;
}

.module tr:hover {
  background-color: #ddd;
}

.module th {
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: left;
  background-color: #007473;
  color: white;
}

.module td:nth-child(1) {
  min-width: 300px;
}

.registryClient {
  margin-top: 2px;
  margin-bottom: 0px;
  cursor: pointer;
  font-size: 14px;
  padding: 5px 8px;
  border: 1px solid #c0c0c0;
}
.registryClient:hover {
  background: #f7f7f7;
}

