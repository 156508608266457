/*
__Seed builder__
  (Read_only) Builder helper
*/
@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Raleway"), local("Raleway-Regular"), url(https://fonts.gstatic.com/s/raleway/v14/1Ptug8zYS_SKggPNyC0IT4ttDfA.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
.module {
  position: relative;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.module select {
  background: white;
  border: 1px solid #d0d0d0;
  width: 100%;
  height: 35px;
}

.multiSelect {
  z-index: 1005;
}

.multiSelect [class$=-menu] {
  z-index: 100005 !important;
  display: inline-block;
  position: relative !important;
}

.jumbotron {
  background-color: #007473;
  color: white;
  margin: 0px;
  padding: 15px 20px;
}
.jumbotron h4 {
  font-size: 21px;
  margin-bottom: 0px;
}

.form {
  background: #fff;
  overflow: auto;
  padding: 17px 40px 50px 40px;
  max-height: 500px;
}

.call {
  background-color: #007473;
  padding: 5px 14px;
  color: rgba(255, 255, 255, 0.95);
  right: 20px;
  top: 5.5px;
  font-size: 15px;
  margin-top: 9px;
  font-family: Raleway;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  cursor: pointer;
}
.call a {
  color: rgba(255, 255, 255, 0.95);
}

.call:hover {
  color: white;
  background-color: #19454f;
}

.title {
  font-size: 18px;
  font-family: Raleway;
}

.subtitle {
  font-size: 13px;
  font-style: italic;
  margin-bottom: 7px;
  margin-top: 7px;
}

.registryClient {
  margin-top: 3px;
  margin-bottom: 7px;
  cursor: pointer;
  font-size: 13px;
  padding: 2px 7px;
  border: 1px solid #ccc;
}
.registryClient:hover {
  background: #f7f7f7;
}

