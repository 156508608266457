/*
__Seed builder__
  (Read_only) Builder helper
*/
@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Raleway"), local("Raleway-Regular"), url(https://fonts.gstatic.com/s/raleway/v14/1Ptug8zYS_SKggPNyC0IT4ttDfA.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
.module {
  width: 100%;
  height: 100%;
}

.module select {
  background: white;
  border: 1px solid #d0d0d0;
  width: 100%;
  height: 35px;
}

.notice {
  padding: 10px;
  border: 1px solid #d0d0d0;
  font-size: 15px;
  margin-bottom: 20px;
}
.notice a {
  color: #1D70A0;
}

.jumbotron {
  background-color: #007473;
  color: white;
  margin: 0px;
  padding: 15px 20px;
}
.jumbotron h4 {
  font-size: 21px;
  margin-bottom: 0px;
}

.download {
  display: inline-block;
  padding: 2px 7px;
  color: #1080B0;
  border: 1px solid #1080B0;
  margin-bottom: 10px;
}

.form {
  background: #fff;
  max-height: 450px;
  overflow: auto;
  padding: 24px 40px 30px 40px;
}

.lbl {
  margin-bottom: 10px;
}

.loading {
  width: 100%;
  height: 100%;
  padding-left: 50px;
  padding-bottom: 20px;
  background: #fff;
}
.loading .status {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 300px;
  padding-top: 15px;
  margin-top: 40px;
  margin-left: -150px;
  text-align: center;
}

.newDoc {
  padding: 3px 12px;
  margin: 0px;
}

.fil {
  display: inline-block;
  width: 350px;
  margin-top: 10px;
  margin-bottom: 15px;
}

.remove {
  float: right;
  margin-top: -25px;
}

.btn {
  padding: 6px 17px;
  border-radius: 4px;
  color: #007473;
  margin-bottom: 0;
}
.btn:hover {
  text-decoration: none;
  color: #007473;
}

.btnGreen {
  border: 1px solid #007473;
  background-color: #007473;
  color: white;
}
.btnGreen:hover {
  background-color: #007473;
  color: white;
}
.btnGreen:active {
  background-color: #007473;
  border-color: #007473;
}
.btnGreen .text {
  color: white;
}
.btnGreen .icon {
  font-size: 20px;
}
.btnGreen .icon path {
  color: white;
}

.registryClient {
  margin-top: 3px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 13px;
  padding: 2px 7px;
  border: 1px solid #ccc;
}
.registryClient:hover {
  background: #f7f7f7;
}

