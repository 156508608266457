@import '_base';

.module {
  position: relative;
  width: 100%;
  @include box-sizing(border-box);
}

.module select {
  background: white;
  border: 1px solid #d0d0d0;
  width: 100%;
  height: 35px;
}
.multiSelect {
  z-index: 1005;
}
.multiSelect [class$="-menu"]{
  z-index: 100005 !important;
  display: inline-block;
  position: relative !important;
}
.jumbotron {
  background-color:  $primary-color;
  color: white;
  margin: 0px;
  padding: 15px 20px;

  h4 {
    font-size: 21px;
    margin-bottom: 0px;
  }
}

.form {
  background: #fff;
  overflow: auto;
  padding: 17px 40px 50px 40px;
  max-height: 500px;
}


.call {
  background-color: $primary-color;
  padding: 5px 14px;
  color: rgba(255, 255, 255, 0.95);
  right: 20px;
  top: 5.5px;
  font-size: 15px;
  margin-top: 9px;
  font-family: Raleway;
  @include border-radius(5px);
  cursor: pointer;

  a {
    color: rgba(255, 255, 255, 0.95);
  }
}

.call:hover {
  color: white;
  background-color: $dark-color;
}

.title {
  font-size: 18px;
  font-family: Raleway;
}

.subtitle {
  font-size: 13px;
  font-style: italic;
  margin-bottom: 7px;
  margin-top: 7px;
}

.registryClient{
  margin-top: 3px;
  margin-bottom: 7px;
  cursor: pointer;
  font-size: 13px;
  padding: 2px 7px;
  border: 1px solid #ccc;
  &:hover{
    background: #f7f7f7;
  }
}