@import '_base';

.module {
  position: relative;
  width: 100%;
  @include box-sizing(border-box);
}

.module select {
  background: white;
  border: 1px solid #d0d0d0;
  width: 100%;
  height: 35px;
}
.multiSelect [class$="-menu"]{
  z-index: 1005;
}
.jumbotron {
  background-color:  $primary-color;
  color: white;
  margin: 0px;
  padding: 15px 20px;

  h4 {
    font-size: 21px;
    margin-bottom: 0px;
  }
}

.form {
  background: #fff;
  max-height: 450px;
  overflow: auto;
  padding: 24px 40px 30px 40px;
}



.module table {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin-top: 12px;
}

.module td, .module th {
  border: 1px solid #ddd;
  padding: 8px;
  i {
    color: #aaa;
    cursor: pointer;
  }
}

.module tr:nth-child(even){background-color: #f2f2f2;}

.module tr:hover {background-color: #ddd;}

.module th {
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: left;
  background-color: $primary-color;
  color: white;
}

.module td:nth-child(1){
  min-width: 300px;
}


.registryClient {
  margin-top: 2px;
  margin-bottom: 0px;
  cursor: pointer;
  font-size: 14px;
  padding: 5px 8px;
  border: 1px solid #c0c0c0;
  &:hover{
    background: #f7f7f7;
  }
}