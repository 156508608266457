@import '_base';

.module {
  width: 100%;
  height: 100%;
}

.background {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: $primary-color;
}

.container {
  position: fixed;
  width: 360px;
  height: auto;
  padding: 40px 40px 32px 40px;
  @include card-design();
  @include align-horizontal(360px);
  @include align-vertical(450px);
  @include box-sizing(border-box);
}

.logo {
  position: fixed;
  display: inline-block;
  height: 60px;
  width: auto;
  top: 30px;
  left: 40px;
  filter: brightness(0) invert(1)
}

.title {
  width: 100%;
  font-size: 24px;
  color: $primary-color;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;

  span {
    display: block;
    margin-top: 2px;
    margin-bottom: 7px;
    font-weight: normal;
    font-size: 0.7em;
  }
}

.emailLbl,
.passwordLbl {
  font-size: 13px;
  margin-top: 12px;
  margin-bottom: 2px;
  text-transform: uppercase;
  color: #777;
}

.email,
.password {
  width: 100%;
  font-size: 15px;
  padding: 4px 8px;
  border: 1px solid #d0d0d0;
}

.remember {
  margin-top: 14px;
  margin-right: 4px;
}

.rememberLbl {
  font-size: 14px;
  color: #999;
}

.error {
  width: 100%;
  margin-top: 5px;
  background-color: #ffeeee;
  color: #a05050;
  font-size: 14px;
  padding: 10px;
  font-weight: bold;
  @include box-sizing(border-box);
}

.submit,
.submitCrz {
  display: inline-block;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 3px;
  width: 100%;
  background: $accent-color;
  color: #fff;
  border: none;
  padding: 11px 5px;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: bold;
  @include border-radius(5px);

  &:hover {
    text-decoration: none;
    color: white;
    text-decoration: underline;
  }
}

.or {
  position: relative;
  left: 50%;
  width: 60px;
  margin-top: -32px;
  margin-left: -31px;
  background: white;
  padding: 2px 2px;
  text-align: center;
  color: #a0a0a0;
}

.submitCrz {
  background: #007473;
}