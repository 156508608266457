/*
__Seed builder__
  (Read_only) Builder helper
*/
@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Raleway"), local("Raleway-Regular"), url(https://fonts.gstatic.com/s/raleway/v14/1Ptug8zYS_SKggPNyC0IT4ttDfA.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
.module {
  width: 100%;
  height: 100%;
  background-color: #e0eaf0;
}

.nav {
  display: flex;
  align-items: center;
  width: 100%;
  text-align: center;
  justify-content: center;
  font-family: Raleway;
}

.item {
  display: block;
  text-align: center;
  margin: 0px 4px 5px 4px;
  padding: 3px 9px 6px 9px;
  color: #007473;
  font-size: 15px;
  text-decoration: none;
}
.item:hover {
  color: #007473;
  font-weight: bold;
  text-decoration: none;
}
.item i {
  margin-left: 5px;
  margin-right: 15px;
}

.item.active {
  color: #007473;
  font-weight: bold;
  border-bottom: 2px #007473 solid;
}

.frame {
  width: 100%;
  height: 100%;
  border: none;
}

.printButton {
  position: absolute;
  left: 50%;
  display: block;
  background: #F0C020;
  text-align: center;
  margin-top: 15px;
  margin-left: 595px;
  padding: 5px 18px 5px 14px;
  color: white;
  font-size: 15px;
  text-decoration: none;
  cursor: pointer;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  border-radius: 30px;
}
.printButton:hover {
  background: #E0A000;
  color: white;
  text-decoration: none;
}
.printButton i {
  margin-left: 5px;
  margin-right: 15px;
}

.footer_1 {
  position: fixed;
  width: 500px;
  height: 40px;
  bottom: 0px;
  background: #eaeaea;
  z-index: 100;
}

.footer_2 {
  position: fixed;
  width: 500px;
  height: 40px;
  bottom: 0px;
  right: 0px;
  background: #eaeaea;
  z-index: 100;
}

