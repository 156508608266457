@import '_base';

.module {
  width: 100%;
  height: 100%;
}

.module a:hover {
  text-decoration: none;
}

.nav {
  display: flex;
  align-items: center;
  width: 100%;
  text-align: center;
  justify-content: center;
  font-family: Raleway;
}


.item {
  display: block;
  background: white;
  text-align: center;
  margin: 9px 7px 8px 7px;
  padding: 5px 18px 5px 14px;
  color: $primary-color;
  border: 1px solid #d0d0d0;
  font-size: 15px;
  text-decoration: none;
  @include border-radius(30px);

  &:hover {
    color: $primary-color;
    background: #f7f7f7;
  }

  i {
    margin-left: 5px;
    margin-right: 15px;
  }
}

.item.active{
  color: white;
  background: $primary-color;
}