/*
__Seed builder__
  (Read_only) Builder helper
*/
@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Raleway"), local("Raleway-Regular"), url(https://fonts.gstatic.com/s/raleway/v14/1Ptug8zYS_SKggPNyC0IT4ttDfA.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
.module {
  width: 100%;
  height: 100%;
}

.background {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #007473;
}

.container {
  position: fixed;
  width: 360px;
  height: auto;
  padding: 40px 40px 32px 40px;
  background-color: #fff;
  border: 1px solid #e7e7e7;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  border-radius: 7px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  left: 50%;
  margin-left: -180px;
  top: 50%;
  margin-top: -225px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.logo {
  position: fixed;
  display: inline-block;
  height: 60px;
  width: auto;
  top: 30px;
  left: 40px;
  filter: brightness(0) invert(1);
}

.title {
  width: 100%;
  font-size: 24px;
  color: #007473;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
}
.title span {
  display: block;
  margin-top: 2px;
  margin-bottom: 7px;
  font-weight: normal;
  font-size: 0.7em;
}

.emailLbl,
.passwordLbl {
  font-size: 13px;
  margin-top: 12px;
  margin-bottom: 2px;
  text-transform: uppercase;
  color: #777;
}

.email,
.password {
  width: 100%;
  font-size: 15px;
  padding: 4px 8px;
  border: 1px solid #d0d0d0;
}

.remember {
  margin-top: 14px;
  margin-right: 4px;
}

.rememberLbl {
  font-size: 14px;
  color: #999;
}

.error {
  width: 100%;
  margin-top: 5px;
  background-color: #ffeeee;
  color: #a05050;
  font-size: 14px;
  padding: 10px;
  font-weight: bold;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.submit,
.submitCrz {
  display: inline-block;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 3px;
  width: 100%;
  background: #ffc000;
  color: #fff;
  border: none;
  padding: 11px 5px;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: bold;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
}
.submit:hover,
.submitCrz:hover {
  text-decoration: none;
  color: white;
  text-decoration: underline;
}

.or {
  position: relative;
  left: 50%;
  width: 60px;
  margin-top: -32px;
  margin-left: -31px;
  background: white;
  padding: 2px 2px;
  text-align: center;
  color: #a0a0a0;
}

.submitCrz {
  background: #007473;
}

