@import '_base';

.module {
  width: 100%;
  height: 100%;
  background-color: #e0eaf0;
}


.nav {
  display: flex;
  align-items: center;
  width: 100%;
  text-align: center;
  justify-content: center;
  font-family: Raleway;
}


.item {
  display: block;
  text-align: center;
  margin: 0px 4px 5px 4px;
  padding: 3px 9px 6px 9px;
  color: $primary-color;
  font-size: 15px;
  text-decoration: none;

  &:hover {
    color: $primary-color;
    font-weight: bold;
    text-decoration: none;
  }

  i {
    margin-left: 5px;
    margin-right: 15px;
  }
}

.item.active{
  color: $primary-color;
  font-weight: bold;
  border-bottom: 2px $primary-color solid;
}

.frame {
  width: 100%;
  height: 100%;
  border: none;
}

.printButton {
  position: absolute;
  left: 50%;
  display: block;
  background: #F0C020;
  text-align: center;
  margin-top: 15px;
  margin-left: 595px;
  padding: 5px 18px 5px 14px;
  color: white;
  font-size: 15px;
  text-decoration: none;
  cursor: pointer;
  @include border-radius(30px);

  &:hover {
    background: #E0A000;
    color: white;
    text-decoration: none;
  }

  i {
    margin-left: 5px;
    margin-right: 15px;
  }
}

.footer_1{
  position: fixed;
  width: 500px;
  height: 40px;
  bottom: 0px;
  background: #eaeaea;
  z-index: 100;
}

.footer_2{
  position: fixed;
  width: 500px;
  height: 40px;
  bottom: 0px;
  right: 0px;
  background: #eaeaea;
  z-index: 100;
}