@import '_base';

$dot-color: #35e35da0;

.module {
  width: 100%;
  height: 100%;
  background-color: $primary-color;
  border-bottom: 1px solid $dark-color;
  color: white;
}

.navLeft {
  display: flex;
  align-items: center;

  .dots {
    width: 42px;
    height: 42px;
    margin-left: 5px;
    margin-right: 15px;
    $bg-color: $primary-color;
    $dot-size: 2px;
    $dot-space: 8px;

    background:
      linear-gradient(90deg, $bg-color ($dot-space - $dot-size), transparent 1%) center,
      linear-gradient($bg-color ($dot-space - $dot-size), transparent 1%) center,
      $dot-color;
    background-size: $dot-space $dot-space;
  }


  .logo {
    width: 110px;
    margin-top: 5px;
    padding-right: 30px;
    margin-right: 30px;
    border-right: 2px solid rgba(255, 255, 255, 0.2);

    img {
      width: 100%;
      filter: brightness(0) invert(1);
    }
  }

  h5 {
    font-size: 16.5px;
    margin-top: 7px;
    margin-bottom: 2px;
    font-family: Geneva, sans-serif;
    color: white;

    span {
      color: rgba(255, 255, 255, 0.6);
      font-size: 14px;
      margin-left: 3px;
    }
  }
}

.menu {
  position: absolute;
  width: 44px;
  height: 44px;
  padding: 8px;
  left: 10px;
  cursor: pointer;
  @include box-sizing(border-box);
}

.title {
  position: absolute;
  top: 10px;
  left: 70px;
  font-size: 17px;
  font-family: Raleway;
  color: white;
  text-transform: capitalize;
}

.call {
  position: absolute;
  background-color: $primary-color;
  padding: 5px 14px;
  color: rgba(255, 255, 255, 0.95);
  right: 57px;
  top: 5.5px;
  font-size: 15px;
  font-family: Raleway;
  @include border-radius(5px);
  cursor: pointer;

  a {
    color: rgba(255, 255, 255, 0.95);
  }

  i {
    margin-right: 7px;
  }
}

.call:hover {
  color: rgba(255,255,255,0.2);
  background-color: $dark-color;
}

.expand {
  position: absolute;
  right: 16px;
  top: 6px;
  cursor: pointer;
  font-size: 16px;
  padding: 2px 12px;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.4);

  &:hover {
    background: rgba(255,255,255,0.2);
  }

  i {
    margin-left: 5px;
    font-size: 19px;
  }
}