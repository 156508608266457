/*
__Seed builder__
  (Read_only) Builder helper
*/
@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Raleway"), local("Raleway-Regular"), url(https://fonts.gstatic.com/s/raleway/v14/1Ptug8zYS_SKggPNyC0IT4ttDfA.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
.module {
  position: relative;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.module select {
  background: white;
  border: 1px solid #d0d0d0;
  width: 100%;
  height: 35px;
}

.multiSelect [class$=-menu] {
  z-index: 1005;
}

.jumbotron {
  background-color: #007473;
  color: white;
  margin: 0px;
  padding: 15px 20px;
}
.jumbotron h4 {
  font-size: 21px;
  margin-bottom: 0px;
}

.form {
  background: #fff;
  max-height: 585px;
  overflow: auto;
  padding: 24px 30px 30px 30px;
}

.registryClient {
  margin-top: 2px;
  margin-bottom: 0px;
  cursor: pointer;
  font-size: 14px;
  padding: 5px 8px;
  border: 1px solid #c0c0c0;
}
.registryClient:hover {
  background: #f7f7f7;
}

.divider {
  background-color: #ddd;
  width: 300px;
  height: 1px;
  display: block;
  margin: 10px 2px 10px 10px;
}

.instructions {
  background: #fafafa;
  border: 1px solid #e0e0e0;
  padding: 10px 18px;
}
.instructions img {
  margin-top: 5px;
  height: 180px;
}

.printButton {
  display: block;
  background: #F0C020;
  text-align: center;
  margin: 15px 7px 8px 7px;
  padding: 8px 18px 8px 14px;
  color: white;
  font-size: 16px;
  text-decoration: none;
  cursor: pointer;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  border-radius: 30px;
}
.printButton:hover {
  background: #E0A000;
  color: white;
  text-decoration: none;
}
.printButton i {
  margin-left: 5px;
  margin-right: 15px;
}

