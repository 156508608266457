@import '_base';

$top-height: 48px;

.module {
  width: 100%;
  height: 100%;
  background-color: #e0eaf0;
  @include box-sizing(border-box);
}

.container {
  position: fixed;
  width: 100%;
  height: 100%;
  @include box-sizing(border-box);
}


.container {
  position: fixed;
  width: 100%;
  height: 100%;
  @include box-sizing(border-box);
}

.topnav {
  position: relative;
  width: 100%;
  height: $top-height;
  overflow: auto;
  background: #fff;
  border-bottom: 1px solid #e7e7e7;
  @include box-sizing(border-box);
}

.content {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
  @include box-sizing(border-box);
}