/*
__Seed builder__
  (Read_only) Builder helper
*/
@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Raleway"), local("Raleway-Regular"), url(https://fonts.gstatic.com/s/raleway/v14/1Ptug8zYS_SKggPNyC0IT4ttDfA.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
.module {
  width: 100%;
  height: 100%;
  background-color: #007473;
  border-bottom: 1px solid #19454f;
  color: white;
}

.navLeft {
  display: flex;
  align-items: center;
}
.navLeft .dots {
  width: 42px;
  height: 42px;
  margin-left: 5px;
  margin-right: 15px;
  background: linear-gradient(90deg, #007473 6px, transparent 1%) center, linear-gradient(#007473 6px, transparent 1%) center, rgba(53, 227, 93, 0.6274509804);
  background-size: 8px 8px;
}
.navLeft .logo {
  width: 110px;
  margin-top: 5px;
  padding-right: 30px;
  margin-right: 30px;
  border-right: 2px solid rgba(255, 255, 255, 0.2);
}
.navLeft .logo img {
  width: 100%;
  filter: brightness(0) invert(1);
}
.navLeft h5 {
  font-size: 16.5px;
  margin-top: 7px;
  margin-bottom: 2px;
  font-family: Geneva, sans-serif;
  color: white;
}
.navLeft h5 span {
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
  margin-left: 3px;
}

.menu {
  position: absolute;
  width: 44px;
  height: 44px;
  padding: 8px;
  left: 10px;
  cursor: pointer;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.title {
  position: absolute;
  top: 10px;
  left: 70px;
  font-size: 17px;
  font-family: Raleway;
  color: white;
  text-transform: capitalize;
}

.call {
  position: absolute;
  background-color: #007473;
  padding: 5px 14px;
  color: rgba(255, 255, 255, 0.95);
  right: 57px;
  top: 5.5px;
  font-size: 15px;
  font-family: Raleway;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  cursor: pointer;
}
.call a {
  color: rgba(255, 255, 255, 0.95);
}
.call i {
  margin-right: 7px;
}

.call:hover {
  color: rgba(255, 255, 255, 0.2);
  background-color: #19454f;
}

.expand {
  position: absolute;
  right: 16px;
  top: 6px;
  cursor: pointer;
  font-size: 16px;
  padding: 2px 12px;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.4);
}
.expand:hover {
  background: rgba(255, 255, 255, 0.2);
}
.expand i {
  margin-left: 5px;
  font-size: 19px;
}

