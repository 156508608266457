/*
__Seed builder__
  (Read_only) Builder helper
*/
@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Raleway"), local("Raleway-Regular"), url(https://fonts.gstatic.com/s/raleway/v14/1Ptug8zYS_SKggPNyC0IT4ttDfA.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
.module {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 4px 30px;
  margin-bottom: 100px;
  background-color: #e0eaf0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.card {
  position: relative;
  width: 790px;
  left: 50%;
  margin-left: -395px;
  padding: 32px 48px;
  margin-bottom: 100px;
  background-color: #fff;
  border: 1px solid #e7e7e7;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  border-radius: 7px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #d0d0d0;
}

.call {
  float: right;
  top: 0px;
  margin-top: 0px !important;
  margin-bottom: 20px;
  background-color: #25d040;
  padding: 5px 14px;
  color: rgba(255, 255, 255, 0.95);
  right: 20px;
  top: 5.5px;
  font-size: 15px;
  margin-top: 9px;
  font-family: Raleway;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  cursor: pointer;
}
.call i {
  margin-right: 7px;
}
.call a {
  color: rgba(255, 255, 255, 0.95);
}

.call:hover {
  color: white;
  background-color: #20c030;
}

.call.excel {
  background-color: white;
  border: 1px solid #a0a0a0;
  color: #666;
  margin-right: 5px;
}
.call.excel i {
  color: #707070;
}

.call.excel:hover {
  background-color: #f0f0f0;
}

.smallCall {
  float: none;
  margin: 0px;
  right: 0px;
  top: 0px;
  padding: 3px 11px;
  display: inline-block;
  background-color: white;
  border: 1px solid #007473;
}
.smallCall a {
  color: #007473;
}

.smallCall:hover {
  background-color: #f0f0f0;
}

.title {
  font-size: 19px;
  font-family: Raleway;
}

.subtitle {
  font-size: 13px;
  font-style: italic;
  margin-bottom: 7px;
  margin-top: 7px;
}

.module table {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin-top: 12px;
}

.module td, .module th {
  border: 1px solid #ddd;
  padding: 8px;
}
.module td i, .module th i {
  color: #aaa;
  cursor: pointer;
}

.module tr:nth-child(even) {
  background-color: #f2f2f2;
}

.module tr:hover {
  background-color: #ddd;
}

.module th {
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: left;
  background-color: #007473;
  color: white;
}

.module td:nth-child(4) {
  max-width: 210px;
  overflow: hidden;
}

.module td:nth-child(5) {
  text-align: center;
}

