@import '_base';

.module {
  position: relative;
  width: 100%;
  @include box-sizing(border-box);
}

.module select {
  background: white;
  border: 1px solid #d0d0d0;
  width: 100%;
  height: 35px;
}
.multiSelect [class$="-menu"]{
  z-index: 1005;
}
.jumbotron {
  background-color:  $primary-color;
  color: white;
  margin: 0px;
  padding: 15px 20px;

  h4 {
    font-size: 21px;
    margin-bottom: 0px;
  }
}

.form {
  background: #fff;
  max-height: 585px;
  overflow: auto;
  padding: 24px 30px 30px 30px;
}


.registryClient {
  margin-top: 2px;
  margin-bottom: 0px;
  cursor: pointer;
  font-size: 14px;
  padding: 5px 8px;
  border: 1px solid #c0c0c0;
  &:hover{
    background: #f7f7f7;
  }
}

.divider{
  background-color: #ddd;
  width: 300px;
  height: 1px;
  display: block;
  margin: 10px 2px 10px 10px;
}
.instructions{

  background: #fafafa;
  border: 1px solid #e0e0e0;
  padding: 10px 18px;

  img{
    margin-top: 5px;
    height: 180px;
  }
}

.printButton {
  display: block;
  background: #F0C020;
  text-align: center;
  margin: 15px 7px 8px 7px;
  padding: 8px 18px 8px 14px;
  color: white;
  font-size: 16px;
  text-decoration: none;
  cursor: pointer;
  @include border-radius(30px);

  &:hover {
    background: #E0A000;
    color: white;
    text-decoration: none;
  }

  i {
    margin-left: 5px;
    margin-right: 15px;
  }
}