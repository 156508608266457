/*
__Seed builder__
  (Read_only) Builder helper
*/
@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Raleway"), local("Raleway-Regular"), url(https://fonts.gstatic.com/s/raleway/v14/1Ptug8zYS_SKggPNyC0IT4ttDfA.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
.module {
  width: 100%;
  height: 100%;
}

.module a:hover {
  text-decoration: none;
}

.nav {
  display: flex;
  align-items: center;
  width: 100%;
  text-align: center;
  justify-content: center;
  font-family: Raleway;
}

.item {
  display: block;
  background: white;
  text-align: center;
  margin: 9px 7px 8px 7px;
  padding: 5px 18px 5px 14px;
  color: #007473;
  border: 1px solid #d0d0d0;
  font-size: 15px;
  text-decoration: none;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  border-radius: 30px;
}
.item:hover {
  color: #007473;
  background: #f7f7f7;
}
.item i {
  margin-left: 5px;
  margin-right: 15px;
}

.item.active {
  color: white;
  background: #007473;
}

