@import '_base';

.module {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 4px 30px;
  margin-bottom: 100px;
  background-color: #e0eaf0;
  @include box-sizing(border-box);
}

.card {
  position: relative;
  width: 790px;
  left: 50%;
  margin-left: -395px;
  padding: 32px 48px;
  margin-bottom: 100px;
  @include card-design();
  border: 1px solid #d0d0d0;
}

.call {
  float: right;
  top: 0px;
  margin-top: 0px !important;
  margin-bottom: 20px;
  background-color: #25d040;
  padding: 5px 14px;
  color: rgba(255, 255, 255, 0.95);
  right: 20px;
  top: 5.5px;
  font-size: 15px;
  margin-top: 9px;
  font-family: Raleway;
  @include border-radius(5px);
  cursor: pointer;

  i {
    margin-right: 7px;
  }

  a {
    color: rgba(255, 255, 255, 0.95);
  }
}

.call:hover {
  color: white;
  background-color: #20c030;
}

.call.excel{
  background-color: white;
  border: 1px solid #a0a0a0;
  color: #666;
  margin-right: 5px;
  i {
    color: #707070;
  }
}

.call.excel:hover{
  background-color: #f0f0f0;
}

.smallCall{
  float: none;
  margin: 0px;
  right: 0px;
  top: 0px;
  padding: 3px 11px;
  display: inline-block;
  background-color: white;
  border: 1px solid $primary-color;
  
  a {
    color: $primary-color;
  }
}

.smallCall:hover{
  background-color: #f0f0f0;
}

.title {
  font-size: 19px;
  font-family: Raleway;
}

.subtitle {
  font-size: 13px;
  font-style: italic;
  margin-bottom: 7px;
  margin-top: 7px;
}

.module table {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin-top: 12px;
}

.module td, .module th {
  border: 1px solid #ddd;
  padding: 8px;
  i {
    color: #aaa;
    cursor: pointer;
  }
}

.module tr:nth-child(even){background-color: #f2f2f2;}

.module tr:hover {background-color: #ddd;}

.module th {
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: left;
  background-color: $primary-color;
  color: white;
}

.module td:nth-child(4){
  max-width: 210px;
  overflow: hidden;
}

.module td:nth-child(5){
  text-align: center;
}